.card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 0.375rem;
  border: 0.5px solid #00000010;
  padding: 0.75rem;
  margin: 1rem 0.5rem;
}

.card-container-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.75rem;
}

.card-container-content-title {
  font-size: 0.9rem;
}

.card-container-content-time {
  font-size: 0.8rem;
}

.card-container-button-container {
  display: flex;
  align-items: center;
}

.card-container-button {
  height: 100%;
  background-color: #5a4bda;
  color: #fff;
  padding: 0.75rem 1.5rem;
  font-weight: bold;
  border: none;
  border-radius: 0.375rem;
  transition: background-color 0.3s ease;
}

.card-container-button:hover {
  background-color: #5a4bda;
}

.card-container-button:focus {
  outline: none;
}

.card-container-button:active {
  background-color: #5a4bda;
}

.mantine-Modal-close {
  margin-left: 0;
  margin-right: 0;
}
