.table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  padding: 1rem;
}

.table thead th {
  background-color: #f5f5f5;
  color: #333;
  font-weight: bold;
  padding: 1rem;
  text-align: left;
}

.table tbody td {
  border: 1px solid #ddd;
  padding: 0.5rem 1rem;
}

.table tbody tr:nth-child(even) td {
  background-color: #f9f9f9;
}

.table tbody tr:hover td {
  background-color: #eaf6ff;
}

.loader {
  height: 5rem;
  width: 5rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
