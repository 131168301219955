.kpi-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border: 1px solid #ccc;
  font-weight: bold;
  border-radius: 0.25rem;
  width: 12.85rem;
  padding: 0.5rem 0.4rem;
}

.not-uploaded {
  /* color: #dc3c3d; */
  border-color: red;
}

.not-published {
  color: black;
  border-color: black;
}

.published {
  /* color: #027a48; */
  border-color: green;
}

.kpi-text {
  font-weight: semi-bold;
  text-align: center;
  align-self: center;
}
