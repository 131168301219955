.upload-thumbnail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: -webkit-fill-available;
  margin-top: 1rem;
}

.upload-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: -webkit-fill-available;

  padding: 0.6rem 0.5rem;
  border: 1px solid #5a4bda;
  border-radius: 0.25rem;
  font-weight: bold;
  background-color: #f1efff;
  color: #5a4bda;
}

.upload-button:hover {
  font-weight: bold;
  background-color: #f1efff;
  color: #5a4bda;
}

.thumbnail-preview {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: -webkit-fill-available;
}

.thumbnail-preview-image {
  width: 100%;
  border-radius: 0.25rem;
  height: auto;
  object-fit: contain;
}

.save-thumbnail-button {
  width: -webkit-fill-available;
  background-color: #5a4bda;
  font-weight: bold;
  color: #fff;
  padding: 0.6rem 0.5rem;
  border: none;
  border-radius: 0.25rem;
  border: 1px solid #5a4bda;
  transition: background-color 0.3s ease;
}

.save-thumbnail-button:hover {
  background-color: #f1efff;
  color: #5a4bda;
  border: 1px solid #5a4bda;
}

.save-thumbnail-button:focus {
  outline: none;
  color: 'white';
}

.save-thumbnail-button:active {
  color: 'white';
  background-color: #553498;
}
