th {
  font-size: 1.15rem;
  font-weight: 300;
  text-align: center;
  position: sticky;
  top: 0;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.35rem;
}

.center-run-time {
  font-size: 0.8rem;
  font-weight: normal;
}

.status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.35rem;
}

.status-ups {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  font-size: 0.8rem;
}
