.center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
}

.center-container-room {
  font-weight: 500;
}

.center-container-time {
  font-weight: 300;
  font-size: 0.85rem;
}
