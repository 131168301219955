.common-styling {
  background-color: #5a4bda;
  font-weight: bold;
  color: #fff;
  padding: 0.6rem 0.5rem;
  border: none;
  border-radius: 0.25rem;
  border: 1px solid #5a4bda;
  transition: background-color 0.3s ease;
}

.common-styling:hover {
  background-color: #f1efff;
  color: #5a4bda;
  border: 1px solid #5a4bda;
}

.common-styling:focus {
  outline: none;
  color: 'white';
}

.common-styling:active {
  color: 'white';
  background-color: #553498;
}

.csv {
  background-color: #5a4bda;
  text-decoration: none;
  font-weight: bold;
  color: #fff;
  font-size: 0.85rem;
  padding: 0.6rem 0.5rem;
  border: none;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease;
}

.csv:hover {
  background-color: #f1efff;
  color: #5a4bda;
  border: 1px solid #5a4bda;
}

.csv:focus {
  outline: none;
}

.csv:active {
  background-color: #553498;
}
