.thumbnail-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnail-container img {
  width: 100%;
  height: 5.25rem;
  border-radius: 0.25rem;
}

.thumbnail-container-schedule {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 0.5rem 0.25rem;
  align-items: center;
  justify-content: center;
  text-align: center;
}
