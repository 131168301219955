.header-container {
  display: flex;
  justify-content: space-between;
  overflow: scroll;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 98vw;
}

.kpi-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.button-holder{
  border: none !important;
  padding: 0px !important;
  padding-block: 0px !important;
  padding-inline: 0px !important;
  cursor: pointer;
  background:white;
}

.red-inactive{
  background: white;
  color: #dc3c3d;
}

.red-active{
  background: red;
  color: white !important;
  border-radius: 4px;
}

.green-inactive{
  background: white;
  color: green;
}

.green-active{
  background: green;
  color:white;
  border-radius: 4px;
}

.profile-time-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.noscr::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.noscr {
  overflow: scroll;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
}
