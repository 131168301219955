.file-type-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.file-type-container img {
  width: 1.5rem;
  height: 1.5rem;
}
