body {
  margin: 0;
  font-family: Gilroy, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Medium.ttf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Regular.ttf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-SemiBold.ttf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Bold.ttf') format('opentype');
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
