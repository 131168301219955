.action-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.action-container img {
  width: 18px;
  height: 18px;
}
