.edit-details-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 1rem;
  gap: 1.25rem;
  padding: 0.75rem;
}

.details-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}

.edit-details-title {
  font-weight: bold;
  border: 1px solid #ccc;
  width: 96%;
  padding: 0.5rem 2%;
  border-radius: 0.25rem;
}

/* ----------------------------------- */

.submit-button {
  background-color: #5a4bda;
  font-weight: bold;
  color: #fff;
  padding: 0.6rem 0.5rem;
  border: none;
  border-radius: 0.25rem;
  border: 1px solid #5a4bda;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #f1efff;
  color: #5a4bda;
  border: 1px solid #5a4bda;
}

.submit-button:focus {
  outline: none;
  color: 'white';
}

.submit-button:active {
  color: 'white';
  background-color: #553498;
}
